<template>
  <div>
    <EtablissementScolaireFormComponent 
      :is-edit-form="false"
    />
  </div>
</template>

<script>
import EtablissementScolaireFormComponent from '../../components/etablissementScolaire/EtablissementScolaireFormComponent';

export default {
  name: 'CreateEtablissementScolaireContainer',
  components: {
    EtablissementScolaireFormComponent,
  },
  data() {
    return {

    };
  },
};
</script>