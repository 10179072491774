<template>
  <div>
    <v-card>
      <div class="pa-5 mb-5">
        <v-card-title
          primary-title
          class="title"
        >
          {{ isEditForm ? "Modifier un établissement": "Créer un établissement" }}
        </v-card-title>
        <v-form
          v-if="etablissement || !isEditForm"
          v-model="valid"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="etablissement.nom"
                v-validate.immediate="'required|max:250'"
                label="Nom *"
                data-vv-name="nom"
                :error-messages="errors.collect('nom')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="etablissement.code_uai"
                v-validate.immediate="'required|regex:^[0-9]{7}[0-9A-Z]$'"
                label="Code UAI *"
                data-vv-name="code uai"
                :error-messages="errors.collect('code uai')"
              />
            </v-col>
            <v-col
              v-if="isUserNational && !isEditForm"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="currentCdpeId"
                v-validate.immediate="'required'"
                label="Département *"
                item-value="id"
                :items="filterDepartements"
                :item-text="(item) => `${item.code} - ${item.nom}`"
                data-vv-name="département"
                :error-messages="errors.collect('département')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim.number="etablissement.cap_accueil"
                v-validate.immediate="'required|numeric'"
                label="Capacité d'accueil *"
                data-vv-name="capacité d'accueil"
                :error-messages="errors.collect('capacité d\'accueil')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="etablissement.cl_id"
                v-validate.immediate="'required'"
                :disabled="isEditForm && getEtablissement.is_etablissement_principal"
                label="Conseil local *"
                :items="conseilLocaux"
                :item-text="item => `${item.code} - ${item.nom}  (${item.adresse_legale.code_postal} ${item.adresse_legale.ville})`"
                item-value="id"
                data-vv-name="conseil local"
                :error-messages="errors.collect('conseil local')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="etablissement.niveaux_enseignement_ids"
                v-validate.immediate="'required'"
                class="mt-5"
                label="Niveau d'enseignement *"
                :items="niveauxEnseignement"
                item-text="libelle"
                item-value="id"
                data-vv-name="niveau enseignement"
                chips
                dense
                multiple
                :error-messages="errors.collect('niveau enseignement')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="etablissement.is_etablissement_principal"
                :disabled="isEditForm && getEtablissement.is_etablissement_principal"
                label="Etablissement principal"
              />
            </v-col>
          </v-row>
          <v-card-title
            primary-title
            class="title mt-5"
          >
            Adresse
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="etablissement.adresse.ligne1"
                v-validate.immediate="'max:50'"
                label="Adresse postale"
                data-vv-name="adresse ligne 1"
                :error-messages="errors.collect('adresse ligne 1')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="etablissement.adresse.ligne2"
                v-validate.immediate="'max:50'"
                label="Adresse complémentaire"
                data-vv-name="adresse ligne 2"
                :error-messages="errors.collect('adresse ligne 2')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <CommuneAutocomplete
                :required="true"
                :loaded-commune="commune"
                field-name="Commune/Code postal"
                @changed="changeEtablissementCommuneAdresse"
              />
            </v-col>
          </v-row>
          <v-card-title
            primary-title
            class="title mt-5"
          >
            Contact
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="etablissement.contact.telephone_fixe"
                v-validate.immediate="'phoneNumber'"
                v-mask="'##.##.##.##.##'"
                append-icon="phone"
                type="phone"
                label="Téléphone fixe"
                data-vv-name="téléphone fixe"
                :error-messages="errors.collect('téléphone fixe')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="etablissement.contact.telephone_mobile"
                v-validate.immediate="'phoneNumber'"
                v-mask="'##.##.##.##.##'"
                append-icon="smartphone"
                type="phone"
                label="Téléphone portable"
                data-vv-name="téléphone portable"
                :error-messages="errors.collect('téléphone portable')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="etablissement.contact.email"
                v-validate.immediate="'email'"
                append-icon="mail"
                type="email"
                label="Email"
                data-vv-name="email"
                :error-messages="errors.collect('email')"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="etablissement.contact.abonne_newsletter"
                label="Newsletter"
              />
            </v-col>
          </v-row>
          <v-card-title
            primary-title
            class="title mt-5"
          >
            Commentaire
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model.trim="etablissement.commentaire"
                class="elevation-1 my-2 px-2"
              />
            </v-col>
          </v-row>
          <v-btn
            class="my-5 mr-5"
            color=""
            @click="onWantLeave"
          >
            Retourner à la liste
          </v-btn>
          <v-btn
            class="my-2"
            type="submit"
            :loading="saving"
            :disabled="saving || !valid || (isEditForm && dontHaveChanges())"
            color="primary"
          >
            Enregistrer
          </v-btn>
        </v-form>
      </div>
    </v-card>
    <ConfirmedModal
      v-if="confirmLeave"
      sentence="Êtes-vous sûr de vouloir quitter cette page ? Des modifications non sauvegardées seront perdues."
      btn-action="Retourner à la liste"
      @close="confirmLeave = false"
      @confirmed="onConfirmLeave"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConfirmedModal from '../reusableComponents/confirmedModal';
import CommuneAutocomplete from '../partials/CommuneAutocomplete';

export default {
  name: 'EtablissementScolaireFormComponent',
  components: {
    ConfirmedModal,
    CommuneAutocomplete,
  },
  props: {
    isEditForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valid: false,
      etablissement: null,
      commune: null,
      etablissementId: null,
      confirmLeave: false,
      saving: false,
      currentCdpeId: null,
    };
  },
  computed: {
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      isUserDepartemental: 'isUserDepartemental',
      userCdpeId: 'getUserCdpeId',
    }),
    ...mapGetters('etablissementScolaire', {
      getEtablissement: 'getEtablissementActif',
      getCloneEtablissement: 'getCloneEtablissementActifForUpdate',
      getPreviousCdpeId: 'getCdpeId',
    }),
    ...mapGetters('conseilLocal', {
      conseilLocaux: 'getFilterConseilsLocaux',
    }),
    ...mapGetters('parametrages/commune', {
      communes: 'getCommunes',
    }),
    ...mapGetters('parametrages/niveauEnseignement', {
      niveauxEnseignement: 'getNiveauxEnseignement',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
  },
  watch: {
    currentCdpeId(cdpeId) {
      this.currentClId = null;
      this.loading = true;
      Promise.all([
        this.loadConseilLocaux(cdpeId),
      ]).then(() => this.loading = false);
    },
  },
  created() {
    if (this.isUserNational) {
      this.loadFilterDepartements();
      this.currentCdpeId = null;
    } else if(this.isUserDepartemental) {
      this.currentCdpeId = this.userCdpeId;
    }

    if (this.isEditForm) {
      this.etablissementId = this.$route.params.etablissementId;
      this.loadEtablissement({ etablissementId: this.etablissementId }).then(() => {
        this.etablissement = this.getCloneEtablissement;
        this.commune = { ... this.getEtablissement.adresse.commune };

        this.$validator.reset();
      
        this.loadConseilLocaux(this.getEtablissement.cl.cdpe_id);
      });
    } else {
      this.etablissement = {
        nom: '',
        code_uai: '',
        cap_accueil: 0,
        cl_id: 0,
        niveaux_enseignement_ids: [],
        is_etablissement_principal: false,
        adresse:  {
          commune_id: 0,
          ligne1: '',
          ligne2: '',
        },
        contact: {
          telephone_fixe: '',
          telephone_mobile: '',
          email: '',
          abonne_newsletter: false,
        }, 
        commentaire: '',
      };
      this.commune =  { id: 0, ville: '', code_postal: '' };
    }
    this.loadNiveauxEnseignement();
  },
  methods: {
    ...mapActions('etablissementScolaire', {
      loadEtablissement: 'loadEtablissementActif',
      updateEtablissement: 'updateEtablissement',
      createEtablissement: 'createEtablissement',
    }),
    ...mapActions('cdpe', {
      loadFilterDepartements: 'loadFilterDepartements',
    }),
    ...mapActions('conseilLocal', {
      loadConseilLocaux: 'loadFilterConseilsLocaux',
    }),
    ...mapActions('parametrages/niveauEnseignement', {
      loadNiveauxEnseignement: 'loadNiveauxEnseignement',
    }),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    changeEtablissementCommuneAdresse(commune) {
      this.etablissement.adresse.commune_id = commune.id;
    },
    save() {
      // anti-spam
      if (this.saving) return;
      this.saving = true;
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.isEditForm) {
            return this.updateEtablissement({ etablissementId: this.etablissementId, etablissement: this.etablissement });
          } else {
            return this.createEtablissement({ etablissement: this.etablissement });
          }
        }
      }).then(() => {
        this.setSuccess({ info: "L'établissement scolaire a bien été sauvegardé" });
      }).finally(() => {
        this.saving = false;
        if (!this.isEditForm) {
          this.$router.push({ name: 'edition-etablissement', params: { etablissementId: this.getEtablissement.id } });
        }
      });
    },

    dontHaveChanges() {
      return this.etablissement.nom === this.getEtablissement.nom &&
        this.etablissement.code_uai === this.getEtablissement.code_uai &&
        this.etablissement.cap_accueil === this.getEtablissement.cap_accueil &&
        this.etablissement.cl_id === this.getEtablissement.cl.id &&
        this.etablissement.niveaux_enseignement_ids.length === this.getEtablissement.niveaux_enseignement.length &&
        this.etablissement.niveaux_enseignement_ids.every(id => this.getEtablissement.niveaux_enseignement.some(il => id === il.id)) &&
        this.etablissement.is_etablissement_principal === this.getEtablissement.is_etablissement_principal &&
        this.etablissement.adresse.commune_id === this.getEtablissement.adresse.commune.id &&
        this.etablissement.adresse.ligne1 === this.getEtablissement.adresse.ligne1 &&
        this.etablissement.adresse.ligne2 === this.getEtablissement.adresse.ligne2 &&
        this.etablissement.contact.telephone_fixe === this.getEtablissement.contact.telephone_fixe &&
        this.etablissement.contact.telephone_mobile === this.getEtablissement.contact.telephone_mobile &&
        this.etablissement.contact.email === this.getEtablissement.contact.email &&
        this.etablissement.contact.abonne_newsletter === this.getEtablissement.contact.abonne_newsletter &&
        this.etablissement.commentaire === this.getEtablissement.commentaire &&
        true
        ;
    },

    onWantLeave() {
      if (this.dontHaveChanges()) {
        this.onConfirmLeave();
      } else {
        this.confirmLeave = true;
      }
    },
    onConfirmLeave() {
      this.confirmLeave = false;
      this.$router.push({ name: 'recherche-etablissement' });
    },
  },
};
</script>